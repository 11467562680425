<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 25 25"
  >
    <g transform="translate(4, 2)">
      <g transform="translate(0 5.976)">
        <path
          class="a"
          d="M17.111,176.4H14.316v-.721a.341.341,0,0,0-.341-.341H6.612a.341.341,0,1,0,0,.682h7.021v2.613a6.494,6.494,0,0,1-3.2,5.59H3.886a6.494,6.494,0,0,1-3.2-5.59v-2.613H2.965a.341.341,0,0,0,0-.682H.341a.341.341,0,0,0-.341.341v2.954a7.146,7.146,0,0,0,2.688,5.59H.341a.341.341,0,0,0-.341.341,2.252,2.252,0,0,0,2.25,2.25H12.884a2.252,2.252,0,0,0,2.25-2.25.341.341,0,0,0-.341-.341H11.628a7.2,7.2,0,0,0,1.578-1.761h.962a3.287,3.287,0,0,0,3.283-3.283v-2.437A.341.341,0,0,0,17.111,176.4Zm-2.7,8.5a1.571,1.571,0,0,1-1.531,1.227H2.25A1.571,1.571,0,0,1,.719,184.9h13.7Zm-.1-6.272v-.187h1.091v.733a1.24,1.24,0,0,1-1.238,1.238h-.077A7.175,7.175,0,0,0,14.316,178.628Zm2.454.545a2.6,2.6,0,0,1-2.6,2.6h-.58a7.116,7.116,0,0,0,.291-.682h.289a1.922,1.922,0,0,0,1.92-1.92V178.1a.341.341,0,0,0-.341-.341H14.316v-.682H16.77Z"
          transform="translate(0 -175.333)"
        />
      </g>

      <g transform="translate(6.428 0)">
        <path
          class="a"
          d="M189.641,1.886l-.177-.223a.888.888,0,0,1,0-1.111.341.341,0,0,0-.534-.424,1.567,1.567,0,0,0,0,1.959l.177.223a1.161,1.161,0,0,1,0,1.452l-.182.229a.341.341,0,1,0,.534.424l.182-.229A1.84,1.84,0,0,0,189.641,1.886Z"
          transform="translate(-188.585 0)"
        />
      </g>

      <g transform="translate(8.317 0.766)">
        <path
          class="a"
          d="M245.069,24.37l-.177-.223a.888.888,0,0,1,0-1.111.341.341,0,0,0-.534-.424,1.567,1.567,0,0,0,0,1.959l.177.223a1.161,1.161,0,0,1,0,1.452l-.182.229a.341.341,0,0,0,.534.424l.182-.229A1.84,1.84,0,0,0,245.069,24.37Z"
          transform="translate(-244.014 -22.484)"
        />
      </g>

      <g transform="translate(4.539 0.766)">
        <path
          class="a"
          d="M134.211,24.371l-.177-.223a.889.889,0,0,1,0-1.111.341.341,0,0,0-.534-.424,1.567,1.567,0,0,0,0,1.959l.177.223a1.161,1.161,0,0,1,0,1.452l-.182.229a.341.341,0,1,0,.534.424l.182-.229A1.84,1.84,0,0,0,134.211,24.371Z"
          transform="translate(-133.156 -22.485)"
        />
      </g>

      <g transform="translate(4.54 5.976)">
        <path
          class="a"
          d="M133.792,175.43a.341.341,0,1,0,.1.241A.344.344,0,0,0,133.792,175.43Z"
          transform="translate(-133.21 -175.33)"
        />
      </g>

      <g transform="translate(10.491 8.93)">
        <path
          class="a"
          d="M309.642,262a.341.341,0,0,0-.341.341,4.884,4.884,0,0,1-1.408,3.411.341.341,0,1,0,.484.48,5.57,5.57,0,0,0,1.605-3.891A.341.341,0,0,0,309.642,262Z"
          transform="translate(-307.795 -262)"
        />
      </g>

      <g transform="translate(9.371 13.434)">
        <path
          class="a"
          d="M275.591,394.31a.341.341,0,0,0-.464-.129l-.008,0a.341.341,0,1,0,.335.594l.008,0A.341.341,0,0,0,275.591,394.31Z"
          transform="translate(-274.945 -394.136)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Rest',
};
</script>

<style lang="scss" scoped>
.a {
  fill: currentColor;
  stroke: currentColor;
  stroke-width:0.3px;
}
</style>
